.pagewrapper {
  padding: 1rem 0;
}

.booking {
  background: #252b48;
  border-radius: 10px;
  padding: 5px 10px;
}
.booking_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.booking_header h4 {
  font-size: 16px;
}
.dailyOverviewCalenderWrapper {
  margin: 1rem 0;
}
.dailyOverviewCalenderWrapper a,
.dailyOverviewCalenderWrappera:hover {
  color: inherit !important;
  text-decoration: none !important;
}
.dailyOverviewCalenderWrapper
  .fc-header-toolbar
  .fc-toolbar-chunk:first-child
  div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dailyOverviewCalenderWrapper
  .fc-header-toolbar
  .fc-toolbar-chunk:first-child
  h2 {
  font-size: 16px;
}
.dailyOverviewCalenderWrapper
  .fc-header-toolbar
  .fc-toolbar-chunk:first-child
  button {
  background: transparent;
  border: none;
  font-size: 12px;
  color: #4fc8e9;
}
.dailyOverviewCalenderWrapper
  .fc-header-toolbar
  .fc-toolbar-chunk:first-child
  button:focus {
  box-shadow: none;
}

.dailyOverViewCalender .fc-scrollgrid {
  border-radius: 20px !important;
  overflow: hidden !important;
  border-color: #8d94be !important;
  border-right-width: thin !important;
  border-bottom-width: thin !important;
}
.dailyOverViewCalender .dayHeaderClassNames,
.dailyOverViewCalender .calender_blocks {
  border-color: #8d94be !important;
}
.fc-theme-standard td,
.fc-theme-standard th {
  border-color: #8d94be !important;
}
.fc-theme-standard:first-child td,
.fc-theme-standard:first-child th {
  border-left: none;
  border-top: none;
}
.dailyOverViewCalender .dayHeaderClassNames {
  border-top: none !important;
  background: #252b48 !important;
  border-color: #8d94be !important;
}
.dailyOverviewCalenderWrapper td,
.dailyOverviewCalenderWrapper th {
  border-right: none !important;
  border-bottom: 0 !important;
}
.dailyOverViewCalender .calender_blocks:first-child {
  border-left: none !important;
}

.calender_event_block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  cursor: pointer;
}
.calender_blocks .calender_event {
  background-color: #252b48 !important;
  padding: 8px;
  border-radius: 10px;
  margin: 0 5px 10px !important;
  border: 1px solid #3788d8;
  box-shadow: none !important;
}
.calender_blocks .calender_event_out {
  border-color: #ff2552;
}

.calender_event .event_block_type {
  color: #4fc8e9;
  text-align: left;
  font-size: 10px;
}

.calender_event .event_block_status {
  color: white;
  text-align: center;
  font-size: 13px;
}

.calender_event .event_block_time {
  color: #4fc8e9;
  text-align: right;
  font-size: 10px;
}

.calender_event_out .event_block_type {
  color: #ff2552;
  text-align: left;
  font-size: 10px;
}

.calender_event_out .event_block_status {
  color: white;
  text-align: center;
  font-size: 13px;
}

.calender_event_out .event_block_time {
  color: #ff2552;
  text-align: right;
  font-size: 10px;
}

.dailyOverviewCalenderWrapper .fc-more-popover {
  background: #252b48 !important;
  border-radius: 5px !important;
  border: none !important;
}

.dailyOverviewCalenderWrapper .fc-more-popover .fc-popover-header {
  background: #1a1f3cb5;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.dailyOverviewCalenderWrapper .fc-timegrid-slot-minor {
  border-top-style: none !important;
}

.dailyOverviewCalenderWrapper .fc-timegrid-slot {
  height: 45px !important;
}

.dailyOverviewCalenderWrapper .fc-timegrid-more-link {
  background-color: #131437;
  color: yellow;
  box-shadow: none;
  border: 1px solid #4fc8e9;
}

.selectPricePerDayCalenderWrapper {
  /* width: 100%; */
}
.selectPricePerDayCalenderWrapper
  .dailyOverViewCalender
  .calender_blocks:first-child {
  border-left: none !important;
}
.selectPricePerDayCalenderWrapper .dailyOverViewCalender .calender_blocks {
  border: none;
  border-left: 1px solid #8d94be !important;
  color: #8d94be;
  overflow: hidden;
}

.selectPricePerDayCalenderWrapper
  .dailyOverViewCalender
  .dayHeaderClassNames:first-child {
  border-left: none !important;
}

.selectPricePerDayCalenderWrapper .dailyOverViewCalender .dayHeaderClassNames {
  padding: 8px;
  font-size: 14px;
}
.selectPricePerDayCalenderWrapper .dailyOverViewCalender {
  width: 100%;
}
.selectPricePerDayContainer {
  display: flex;
  justify-content: space-between;
  /* align-items: flex-start; */
  gap: 1rem;
}

.selectPricePerDayContainer .selectPricePerDayCalenderWrapper {
  width: 100%;
}

.selectPricePerDayContainer .selectPricePerDay .info_wrapper {
  margin: 1rem 0;
}
.selectPricePerDayContainer .selectPricePerDay {
  width: 45%;
  padding: 2rem 1rem;
  background: #252b48;
  border-radius: 10px;
}
.selectPricePerDayContainer .selectPricePerDay .selectPricePerDay_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1rem;
}
.selectPricePerDayContainer
  .selectPricePerDay
  .selectPricePerDay_header
  .title {
  font-size: 18px;
  font-weight: bold;
}
.selectPricePerDayContainer .selectPricePerDay .selectPricePerDay_header a {
  width: max-content;
}
.selectPricePerDayContainer .selectPricePerDay .selectPricePerDay_descp {
  color: #c0c0c0;
  font-size: 13px;
}
.selectPricePerDayContainer .selectPricePerDay .editPriceBtn {
  background-color: #4fc8e9;
  color: white;
  width: 100%;
  border-radius: 5px;
}

.selectPricePerDayContainer .selectPricePerDay .info_wrapper .price_recomm {
  color: #4fc8e9;
  margin-top: 5px;
}
.price_per_day_calender_event {
  width: 100%;
  /* height: 30px; */
}

.selectPricePerDayCalenderWrapper
  .dailyOverViewCalender
  .calender_blocks
  .fc-daygrid-day-top {
  justify-content: center;
}
.selectPricePerDayCalenderWrapper
  .dailyOverViewCalender
  .calender_blocks
  .fc-daygrid-day-top {
  position: relative;
  z-index: 10;
}
.selectPricePerDayCalenderWrapper
  .dailyOverViewCalender
  .calender_blocks
  .fc-daygrid-dot-event:focus::after {
  background-color: transparent;
}
/* .selectPricePerDayCalenderWrapper
  .dailyOverViewCalender
  .calender_blocks
  .fc-daygrid-day-events {
  z-index: -1;
} */
.price_per_day_event_block {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  height: 85px;
  align-items: center;
  /* background: #2e203a; */
  background: var(--fc-today-bg-color, rgba(255, 220, 40, 0.15));
  left: -2px;
  /* color: #ff2552; */
  color: #f0ce4ad4;
}
.price_per_day_event_block.Booked {
  color: #ff2552;
  background: #2e203a;
}
.default_price .price_per_day_event_block {
  background: #1c2038;
  color: #aeafb2;
  font-size: 12px;
}

.go_back {
  cursor: pointer;
  padding: 10px 0;
  width: 30px;
}

.unavailability_event_block {
  cursor: pointer;
}

.unavailabilityModal .reason {
  font-weight: bold;
  font-size: 16px;
}
.unavailabilityModal .reason span {
  font-weight: normal;
  font-size: 14px;
}

.deleteUnavailability button {
  width: 100% !important;
  margin: 10px 0;
}
