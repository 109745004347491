.input_row_register {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.input_row_register .register_field {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.input_row_register .login_input input {
  background: var(--primary-BG);
  border: 1px solid var(--primary-border-color);
  border-radius: 3px;
  width: 100%;
  outline: none;
  height: 100%;
  padding: 8px 15px;
  color: var(--text-white);
  font-size: 14px;
}
