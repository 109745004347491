.featuresCard {
  margin: 1rem 0;
}
.featuresCard .features_heading {
  font-size: 18px;
  color: var(--text-white);
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.featuresCard .features_heading .featureExpandBtn {
  font-size: 16px;
  cursor: pointer;
  color: var(--light-blue);
}
.featuresCard .features_main {
  border: 1px solid #394164;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 1rem;
  font-size: 10px;
  height: 70px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}
.featuresCard .showFeatures {
  height: 370px;
}
.featuresCard .features_main .feature {
  width: 25%;
  margin: 1rem 0;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.featuresCard .features_main .feature .feature_svg {
  border: 3px solid var(--light-blue);
  display: inline-block;
  transform: rotate(45deg);
  margin-right: 1rem;
  border-radius: 5px;
  margin-top: 8px;
}
.featuresCard .features_main .feature .feature_svg img {
  transform: rotate(-45deg);
  margin: 5px;
}
.featuresCard .more_features_btn {
  background-color: var(--light-blue);
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: var(--text-white);
  margin-top: 2rem;
}

.featuresCard .more_features_btn svg {
  transform: rotate(90deg);
  fill: var(--text-white);
  margin-left: 10px;
}

@media (max-width: 480px) {
  .featuresCard .features_main {
    width: 100%;
  }
  .featuresCard .features_main .feature {
    width: 100%;
  }
}
