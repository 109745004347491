.car_detail_carousel .slider-wrapper {
  border-radius: 30px;
}
.car_detail_carousel .thumbs {
  display: flex;
  justify-content: center;
  align-items: center;
}
.car_detail_carousel .thumbs .thumb {
  width: 100px !important;
  height: 100px;
  border: none;
}

.car_detail_carousel .thumbs .thumb img {
  border-radius: 15px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
}
.car_detail_carousel .unavailableMark {
  /* height: 270px; */
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #413737c7;
  border-radius: 20px;
  font-size: 40px;
  color: #fff;
  text-transform: uppercase;
}

@media (max-width: 480px) {
  .car_detail_carousel .thumbs .thumb {
    height: 45px;
  }
  .car_detail_carousel .thumbs .thumb img {
    height: 45px;
  }
}
