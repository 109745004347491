.productDetail {
  margin-top: 2rem;
}

.product_detail_carousel_pagination {
  margin: 2rem 2rem;
}
.product_detail_carousel_pagination
  .MuiPagination-ul
  li
  button[aria-current='true'] {
  background: transparent;
  color: var(--light-blue);
}
.product_detail_carousel_pagination .MuiPagination-ul {
  justify-content: center;
  gap: 10px;
  flex-wrap: inherit;
}
.product_detail_carousel_pagination .MuiPagination-ul li:first-child button,
.product_detail_carousel_pagination .MuiPagination-ul li:last-child button {
  border: 1px solid var(--light-blue);
  padding-top: 8px;
  padding-left: 10px;
  /* background-color: #284862; */
}
.product_detail_carousel_pagination
  .MuiPagination-ul
  li:first-child
  button
  svg {
  fill: transparent;
  background: url('/public/assets/svg/arrow-left.svg') no-repeat;
  opacity: 1;
}
.product_detail_carousel_pagination .MuiPagination-ul li:last-child button svg {
  fill: transparent;
  background: url('/public/assets/svg/arrow-right.svg') no-repeat;
  opacity: 1;
}

.product_detail_carousel_pagination .MuiPagination-ul li button {
  color: transparent !important;
}
.product_detail_carousel_pagination
  .MuiPagination-ul
  li:not(:first-child):not(:last-child)
  button {
  width: 100px;
  height: 100px;
  border-radius: 15px;
}
.product_detail_carousel_pagination .MuiPagination-ul li:nth-child(2) button {
  background: url(/public/assets/imgs/cars/car_1.png) no-repeat;
  background-size: cover;
  background-position: center center;
}
.product_detail_carousel_pagination .MuiPagination-ul li:nth-child(3) button {
  background: url(/public/assets/imgs/cars/car_2.png) no-repeat;
  background-size: cover;
  background-position: center center;
}
.product_detail_carousel_pagination .MuiPagination-ul li:nth-child(4) button {
  background: url(/public/assets/imgs/cars/car_3.png) no-repeat;
  background-size: cover;
  background-position: center center;
}
.product_detail_carousel_pagination .MuiPagination-ul li:nth-child(5) button {
  background: url(/public/assets/imgs/cars/car_1.png) no-repeat;
  background-size: cover;
  background-position: center center;
}

.productDetail_hero_section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.productDetail_hero_section .img_section {
  width: 50%;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  border-radius: 30px;
  /* max-width: 560px; */
}
.productDetail_hero_section .img_section img {
  max-width: 100%;
  border-radius: 30px;
  object-fit: contain;
  aspect-ratio: 16/11;
  /* height: calc((700px / 16) * 9); */
}

.productDetail_hero_section .img_detail {
  width: 50%;
  padding: 0 0 0 6rem;
  /* min-width: calc(-560px + 100%); */
}

.productDetail .img_detail .product_tags {
  padding-bottom: 10px;
}
.productDetail .img_detail .product_tag {
  background-color: #1d4562;
  color: var(--light-blue);
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 12px;
}
.productDetail .img_detail .product_title {
  font-size: 1.8rem;
  font-weight: bold;
  padding-bottom: 10px;
  cursor: default;
}

.productDetail .img_detail .product_similar {
  color: var(--light-blue);
  font-size: 12px;
  padding-bottom: 10px;
}
.productDetail .img_detail .product_rating {
  margin-bottom: 10px;
}
.productDetail .img_detail .product_short_descp {
  color: #c0c0c0;
  margin-bottom: 10px;
}

.date_row {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.date_row .time_date_picker {
  width: 200px;
}
.date_row .MuiOutlinedInput-notchedOutline,
.date_row .MuiOutlinedInput-notchedOutline:hover {
  border: 1px solid var(--light-blue) !important;
  /* border: none !important; */
  /* background-color: var(--secondary-BG); */
  color: var(--text-white);
}
.date_row .time_date_picker label,
.date_row .time_date_picker .MuiOutlinedInput-root,
.date_row svg {
  color: var(--text-white);
  font-size: 12px;
}
.productDetail .car_tags {
  font-size: 13px;
  gap: 3px;
}
.productDetail .car_tags span img {
  max-width: 30px;
}
.productDetail .product_buttons {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.productDetail .product_buttons .product_buy_now {
  text-decoration: none;
  color: var(--text-white);
  background-color: var(--light-blue);
  padding: 8px 0;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  user-select: none;
  width: 145px;
  text-align: center;
  border: none;
}
.productDetail .product_buttons .unavailable {
  background-color: #bb2d2d;
}

.productDetail .product_buttons .product_totalCost,
.productDetail .product_buttons .product_dailyCost {
  text-decoration: none;
  color: var(--light-blue);
  background-color: var(--secondary-BG);
  padding: 10px 0;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  user-select: none;
  width: 145px;
  text-align: center;
}

.product_description .product_description_navbar {
  color: #9d9fa2;
  font-size: 16px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 2rem;
  border-top: 1px solid var(--secondary-BG);
  border-bottom: 1px solid var(--secondary-BG);
}
.product_description .product_description_navbar span {
  padding: 10px 0;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  border-bottom: 1px solid transparent;
  user-select: none;
}
.product_description .product_description_main {
  display: flex;
  align-items: flex-start;
}

.product_description_main {
  padding: 1rem 0;
}

.product_description_main .description_detail {
  width: 55%;
  font-size: 15px;
  color: #bebebe;
}
.product_description_main .description_detail .description {
  transition: all 0.2s ease-in-out;
}

.product_description_main .description_detail .read_more {
  color: var(--light-blue);
  cursor: pointer;
  user-select: none;
}
.product_description_main .description_detail .read_more svg {
  transform: rotate(90deg);
  margin-left: 8px;
  margin-bottom: 4px;
  width: 5px;
  transition: all 0.5s ease-in-out;
}
.product_description_main .description_detail .read_less svg {
  transform: rotate(270deg);
}

.product_description_main .description_video {
  width: 45%;
  padding: 0 0 0 6rem;
}

.product_description .description_video .description_video_heading {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 1rem;
}
.product_description .description_video .description_video_main video {
  max-width: 100%;
  border-radius: 10px;
}

.productDetailCalenderWrapper .price_per_day_event_block {
  height: 72px;
}
.productDetailCalenderWrapper .bookingSelectedFirst {
  /* border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
  border: 1px solid;
  border-right: none;
  background-color: var(--primary-BG) !important;
  margin-top: -1px; */
}
.productDetailCalenderWrapper .bookingSelectedMid {
  /* border-top: 1px solid;
  border-bottom: 1px solid;
  background-color: var(--primary-BG) !important; */
}
.productDetailCalenderWrapper .bookingSelectedLast {
  /* border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  border: 1px solid;
  border-left: none;
  background-color: var(--primary-BG) !important;
  margin-top: -1px; */
}

.productDetailCalenderWrapper .event_block_type svg {
  display: none;
}

@media (max-width: 1680px) {
  .productDetail_hero_section .img_detail {
    padding-left: 1rem;
  }
}

@media (max-width: 1000px) {
  .productDetail_hero_section .img_detail {
    min-width: auto;
  }
  .productDetailCalenderWrapper .event_block_type .title {
    display: none;
  }
  .productDetailCalenderWrapper .event_block_type svg {
    display: block;
    width: 20px;
    fill: #f0ce4ad4;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .productDetail_hero_section {
    flex-direction: column;
  }
  .productDetail_hero_section .img_section {
    width: 100%;
  }
  .productDetail_hero_section .img_detail {
    width: 100%;
    padding: 0;
  }
  .product_detail_carousel_pagination
    .MuiPagination-ul
    li:not(:first-child):not(:last-child)
    button {
    width: 50px;
    height: 50px;
    border-radius: 8px;
  }
  .date_row {
    align-items: start;
    gap: 0rem;
    flex-direction: column-reverse;
  }
  .date_row .data_time,
  .date_row .time_date_picker {
    width: 100%;
  }
  .productDetail .product_buttons {
    /* display: none; */
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .productDetail .product_buttons .product_buy_now {
    width: 60%;
  }
  .product_description {
    margin-top: 2rem;
  }
  .product_description .product_description_main {
    flex-direction: column;
  }
  .product_description_main .description_detail {
    width: 100%;
  }
  .product_description .description_video {
    padding: 0;
    margin-bottom: 1rem;
    width: 100%;
  }
  .productDetailCalenderWrapper .event_block_type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .productDetailCalenderWrapper .event_block_type .title {
    display: none;
  }
  .productDetailCalenderWrapper .event_block_type svg {
    display: block;
    width: 20px;
    fill: #f0ce4ad4;
    margin-top: 10px;
  }
  .productDetailCalenderWrapper
    .selectPricePerDayCalenderWrapper
    .fc-media-screen {
    height: 420px !important;
  }
  .productDetailCalenderWrapper
    .selectPricePerDayCalenderWrapper
    .fc-daygrid-body
    table {
    height: 377px !important;
  }
  .productDetailCalenderWrapper .price_per_day_event_block {
    height: 56px;
    margin-top: -15px;
  }
  .productDetailCalenderWrapper .bookingSelectedFirst,
  .productDetailCalenderWrapper .bookingSelectedLast {
    margin-top: -16px;
  }
}

.width100 {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.productDetailCalenderWrapper .bookingSelected {
  background-color: #2e203a !important;
}

.prodictTimingsLocation {
  display: flex;
  justify-content: space-around;
  gap: 15px;
}

@media (max-width: 1150px) {
  .prodictTimingsLocation {
    display: block;
  }
  .prodictTimingsLocation div {
    width: 100%;
  }
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: unset!important;
  color: white!important
}