.errorModal .header {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.errorModal .header svg {
  cursor: pointer;
}

.errorModal .editModal {
  width: 400px;
}
