.confirmModal .header {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.confirmModal .header svg {
  cursor: pointer;
}

.confirmModal .editModal {
  width: 400px;
}

.confirmModal .confirm_modal_btn {
  margin-top: 10px;
}

.confirmModal .confirm_modal_btn button {
  background-color: #4fc8e9 !important;
  color: black
}
