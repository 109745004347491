.info_page {
  padding: 3rem 3%;
  font-family: 'Circular Std', sans-serif !important;
  overflow: hidden;
  position: relative;
}
.info_background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 1rem;
}

.info_background img {
  position: absolute;
  max-width: 1550px;
  top: -6rem;
  max-height: 100vh;
}
.info_background .bg_wrapper {
  position: absolute;
  background: radial-gradient(
    52.11% 164.69% at 100% 0%,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(28, 32, 56, 0.84) 31.81%,
    var(--primary-BG) 100%
  );
  width: 100%;
  /* height: 1400px; */
  height: 100vh;
  top: -30px;
}

@media (max-width: 1000px) {
  .info_background img {
    display: none;
  }
  .info_background {
    display: none;
  }
  .info_background .bg_wrapper {
    display: none;
  }
}

@media (max-width: 480px) {
  .info_background {
    overflow: hidden;
  }
  .info_background img {
    left: -470px;
    max-width: 1720px;
  }

  .info_background .bg_wrapper {
    width: 120vw;
    left: -1rem;
  }
}

.paymentDetails_wrapper {
  position: relative;
}

.MuiModal-root[aria-labelledby='conf_modal'] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.MuiModal-root[aria-labelledby='conf_modal'] .MuiBackdrop-root {
  background: var(--back-drop);
}
.MuiInputLabel-outlined[data-shrink='true'] {
  transform: translate(14px, -9px) scale(0);
}