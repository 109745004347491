.footer {
  padding: 2rem 3% 1rem;
  background: var(--secondary-BG);
  color: var(--text-white) !important;
  font-family: Circular Std, sans-serif !important;
  font-weight: 450 !important;
  z-index: 100;
  position: relative;
}
.footer_sections {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  /* flex-wrap: wrap; */
  padding-bottom: 1rem;
}

.footer_logo {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer_logo img {
  max-width: 100%;
}

.footer_sub_section {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 0 1rem;
  gap: 1rem;
}

.footer_section_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.footer_section_row .footer_section_row_item {
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px 25px;
  font-size: 14px;
  cursor: pointer;
  width: 150px;
  text-align: center;
}

.footer_section_row .footer_section_row_item:hover {
}

.footer_svg {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 150px;
}
.footer_svg svg {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.footer_heading {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  background: linear-gradient(180deg, #4fc8e9 99.99%, #ff2552 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.footer_section_main {
  width: 20%;
}
.footer_section {
  margin-bottom: 1rem;
}
.footer_section .footer_section_heading {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  user-select: none;
  color: var(--text-white);
}

.footer_section .footer_section_body {
  /* font-family: 'Circular Std'; */
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  color: var(--text-grey);
  user-select: none;
  cursor: pointer;
}

.footer_buttons {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}
.footer_buttons div img {
  max-width: 100px;
  height: 30px;
  cursor: pointer;
}

.footer_footer {
  /* border-top: 1px solid var(--text-white); */
  padding: 1rem 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  width: 100%;
}
.footer_footer .footer_terms {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.footer_footer .footer_terms div,
.footer_footer .footer_copyright div {
  user-select: none;
  border: 1px solid white;
  border-radius: 5px;
  padding: 5px 25px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  width: 100%;
}
.footer_footer .footer_copyright {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.cls-1 {
  fill: url(#radial-gradient);
}
.cls-2 {
  fill: #fff;
}

@media (max-width: 480px) {
  .footer {
    padding: 0rem 3% 1rem;
  }
  .footer_section_main {
    width: 50%;
  }
  .footer_sections {
    flex-direction: column;
  }
  .footer_logo {
    width: 100%;
  }
  .footer_logo img {
    max-width: 70%;
  }
  .footer_sub_section {
    width: 100%;
  }
  .footer_heading {
    font-size: 2rem;
  }
  .footer_footer {
    flex-direction: column;
    gap: 1rem;
  }
  .footer_footer .footer_terms {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
  }
  .footer_buttons {
    justify-content: center;
  }
  .footer_buttons div img {
    max-width: inherit;
    height: 54px;
  }
  .footer_copyright {
    width: 100%;
  }
}
