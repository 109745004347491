.carCard_wrapper {
  background-color: var(--secondary-BG);
  border-radius: 10px;
  padding: 1rem;
  width: 19%;
  border: none;
  /* height: 468px; */
  /* width: fit-content; */
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.carCard_wrapper:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 5px 2px var(--secondary-box-shadow);
}
.carCard_wrapper .car_costs .daily_rate,
.total_cost,
.price_details {
  font-size: 15px !important;
}
.carCard_hero_img {
  height: 273px;
  position: relative;
}
.carCard_hero_img .unavailableMark {
  /* height: 270px; */
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #413737c7;
  border-radius: 20px;
  font-size: 40px;
  color: #fff;
  text-transform: uppercase;
}
.carCard_hero_img img {
  /* max-width: 100%; */
  border-radius: 20px;
  width: 100%;
  /* height: 270px; */
  object-fit: contain;
  margin-bottom: 6px;
  aspect-ratio: 16/11;
}
.mimTripDurationBatch {
  font-size: 12px;
  border: 1px solid var(--light-blue);
  padding: 4px 8px;
  border-radius: 6px;
  float: right;
}
.carCard_body {
  margin-top: 15px;
  padding-top: 1rem;
}
.carCard_title {
  font-size: 20px;
  font-weight: bold;
  color: var(--text-white);
  text-align: left;
  min-height: 60px;
}
.car_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 10px 0; */
}
.car_details .car_similar_to {
  font-size: 10px;
  color: #bebebe;
}
.car_details .price_details {
  font-size: 10px;
  color: var(--text-white);
}
.car_details .price_details svg {
  transform: rotate(90deg);
  margin-left: 10px;
  width: 6px;
}

.car_tags {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 10px 0;
  gap: 10px;
  font-size: 15px;
  color: var(--text-white);
}
.car_tags span:first-child {
  text-transform: capitalize;
}
.car_tags span img {
  background-color: var(--primary-BG);
  padding: 5px;
  border-radius: 100%;
  max-width: 25px;
  margin-right: 5px;
}

.car_costs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  font-weight: bold;
  color: var(--text-white);
}
.car_costs .total_cost {
  color: var(--light-blue);
}

.car_select_btn {
  margin: 10px 0 0;
}
.car_select_btn a,
.car_select_btn button {
  text-decoration: none;
  display: inline-block;
  text-align: center;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-BG);
  width: 100%;
  padding: 5px;
  color: var(--text-white);
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}
.car_select_btn .select_btn:hover {
  background-color: var(--light-blue);
}

.car_select_btn .unavailable {
  background-color: #bb2d2d;
}

@media (max-width: 2000px) {
  .carCard_wrapper {
    width: 24%;
  }
}
@media (max-width: 1500px) {
  .carCard_wrapper {
    width: 32%;
  }
}

@media (max-width: 1000px) {
  .carCard_wrapper {
    width: 47%;
  }
}

@media (max-width: 480px) {
  .carCard_wrapper {
    width: 100%;
  }
}
