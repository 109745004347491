.featuredPage {
  padding: 2rem 3%;
}
.Mui-focused .featuredPage_sortOptions .MuiFormControl-root {
  margin: 0;
  margin-top: 10px;
}
.featuredPage_sortOptions .MuiInputLabel-root,
.featuredPage_sortOptions .MuiInputLabel-root .Mui-focused {
  font-size: 12px;
  color: var(--text-white) !important;
}

.featuredPage_sortOptions .MuiInputBase-root {
  font-size: 11px;
  /* width: 280px; */
  border: 1px solid var(--text-white);
  color: var(--text-white);
}
.featuredPage_sortOptions .MuiOutlinedInput-notchedOutline,
.featuredPage_sortOptions .MuiOutlinedInput-notchedOutline:hover {
  /* border: 1px solid #ffffff !important; */
  border: none !important;
  color: var(--text-white);
}
.featuredPage_sortOptions svg {
  color: var(--text-white);
  font-size: 14px;
}
.MuiMenu-paper {
  background-color: var(--secondary-BG) !important;
  color: var(--text-white) !important;
  font-size: 14px !important;
  /* width: 280px; */
  /* left: 192px !important; */
}
.airport_name_menu_item {
  font-size: 11px !important;
}
.featuredPage_sortOptions {
  margin: 1rem 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.sortOptions_no_of_passenger .MuiFormControl-root {
  width: 180px;
}

.featuredPage_sortOptions .attribute_wrapper {
  display: flex;
  justify-content: start;
  gap: 1rem;
}
.featuredPage_sortOptions .sortOptions_mileage {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  font-weight: bold;
  font-size: 14px;
}

.featured_card_wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.booking_note {
  margin: 1rem 0;
  font-size: 14px;
}

@media (max-width: 480px) {
  .featuredPage_sortOptions {
    /* display: none; */
    flex-direction: column;
    margin: 1rem 0 1rem;
  }
  .featuredPage_sortOptions .attribute_wrapper {
    flex-wrap: wrap;
    width: 100%;
  }
  .featuredPage_sortOptions .sortOptions_mileage {
    justify-content: flex-start;
    width: 100%;
  }
  .sortOptions_no_of_passenger,
  .sortOptions_size,
  .sortOptions_car_type {
    width: 45%;
  }
  .sortOptions_no_of_passenger .MuiFormControl-root,
  .sortOptions_size .MuiFormControl-root,
  .sortOptions_car_type .MuiFormControl-root {
    width: 100%;
    min-width: auto;
  }
  .sortOptions_mileage {
    margin-top: 10px;
    /* margin-right: 12px; */
  }
}

.toogle_light_color {
  color: #4fc8e9;
}

.choose-filter-btn {
  width: 200px !important;
  color: white !important;
}
.sortOptions_mileage>.MuiFormControl-root {
  min-width: 180px;
}
@media (max-width: 480px) {
  .sortOptions_mileage>.MuiFormControl-root {
    min-width: 120px;
  }
}
