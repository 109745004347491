body {
  background: var(--primary-BG) !important;
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

main img {
  max-width: 100%;
  height: 75vh;
  width: 100%;
  object-fit: cover;
}
.img_wrapper {
  /* background: var(--img-wrap-bg); */
  width: 100%;
  height: 100%;
  position: absolute;
}
.hero_text {
  position: absolute;
  top: 0;
  margin-top: 7rem;
  margin-top: 3rem;
  color: var(--text-white);
  z-index: 1;
  font-size: 45px;
  font-weight: bold;
  text-align: center;
  width: 900px;
}
.card_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 3rem; */
}
.card_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 87%;
  margin: 3rem 0;
}
.homePage .timingsCard_wrapper {
  margin-top: -14rem;
}

@media (max-width: 1200px) {
  .homePage .timingsCard_wrapper {
    margin-top: -12rem;
  }
  .timingsCard {
    width: auto;
  }
  .timingsCard .fieldsWrapper {
    flex-direction: column;
  }
}

@media (max-width: 800px) {
  .homePage .timingsCard_wrapper {
    margin-top: -1rem;
  }
  .card_container {
    margin: 11rem 0 3rem;
  }
}
@media (max-width: 480px) {
  main img {
    max-width: fit-content;
    width: 840px;
    height: 440px;
  }
  .hero_text {
    font-size: 1.5rem;
    width: auto;
  }
  .card_container {
    flex-direction: column;
    width: auto;
    padding: 0 5%;
  }
}

.homePage {
  overflow: hidden;
  position: relative;
}

.homePage input.MuiInputBase-input.MuiOutlinedInput-input,
.timingsCard_wrapper input.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 9px 14px !important;
}

@media (max-width: 480px) {
  main img {
    max-width: fit-content;
    width: 840px;
    height: 440px;
  }
  .hero_text {
    font-size: 1.5rem;
    width: auto;
  }
  .card_container {
    flex-direction: column;
    width: auto;
    padding: 0 5%;
  }
}

@media (orientation: landscape) {
  .hero_text {
    width: 500px;
  }

  .landingTimingsCard {
    position: relative;
  }
}
