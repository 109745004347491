.paymentDetails_wrapper {
  padding: 0 0 4rem;
}
.paymentDetails_wrapper .paymentDetails_container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 5rem;
}
.paymentDetails_wrapper .paymentDetails_card {
  /* width: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.paymentDetails_wrapper .paymentDetails_card img {
  max-width: 300px;
  margin-top: 3rem;
}

.paymentDetails {
  width: 50%;
  z-index: 100;
  margin: auto
}

.paymentDetails .paymentDetails_heading_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.paymentDetailMsgs {
  color: red;
}
.paymentDetails .paymentDetails_heading {
  font-size: 1.8rem;
  font-weight: bold;
}

.paymentDetails .card_details {
  margin: 0;
}

.card_details .card_number_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.card_label {
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1;
}
.card_label span {
  font-size: 14px;
  color: #9d9fa2;
  font-weight: normal;
}

.card_number_header .card_edit {
  color: var(--light-blue);
  display: flex;
  gap: 5px;
  cursor: pointer;
  padding: 0.1rem 0.4rem;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;
}
.editing {
  border-color: var(--light-blue) !important;
  /* text-decoration: underline; */
}
.card_number .sc-bczRLJ,
.card_number .fljFRU {
  width: 100%;
  margin: 1rem 0;
}

.card_number .sc-gsnTZi,
.card_number .eHZVqz,
.card_number .fpydRL,
.card_number .bVWHDv,
.card_number .drLbWb {
  background-color: transparent !important;
  border: 1px solid var(--primary-border-color) !important;
  box-shadow: none !important;
}
.card_number .sc-gsnTZi input,
.card_number .eHZVqz input,
.card_number .fpydRL input,
.card_number .bVWHDv input,
.card_number .drLbWb input {
  background-color: transparent !important;
  color: var(--text-white);
  width: 100% !important;
}
/* eHZVqz */

.cardRow {
  display: flex;
  margin: 1.5rem 0;
  color: var(--text-white);
}

.cardRow_text {
  width: 50%;
}

.cvv_number_text .card_label {
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1;
}
.cvv_number_text .card_label span {
  font-size: 14px;
  color: #9d9fa2;
  font-weight: normal;
}

.cardRow_input {
  width: 50%;
  position: relative;
}

.cardRow_input input {
  background: transparent;
  border: 1px solid var(--primary-border-color);
  border-radius: 3px;
  width: 100%;
  outline: none;
  height: 100%;
  padding-left: 10px;
  color: var(--text-white);
}
.cvv_number .cvv_number_input svg {
  position: absolute;
  right: 10px;
  top: 4px;
  cursor: pointer;
}
.cvv_visible path {
  stroke: var(--light-blue);
}

.expiry .expiry_input {
  /* width: 50%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expiry .expiry_input input {
  background: transparent;
  border: 1px solid var(--primary-border-color);
  border-radius: 3px;
  width: 45%;
  outline: none;
  height: 100%;
  padding-left: 10px;
  text-align: center;
}

.expiry .expiry_input .expiry_separator {
  height: 100%;
  width: 1px;
  transform: rotate(15deg);
  background: #9d9fa2;
}

.confirmation_btn {
  margin-top: 3rem;
}

.confirmation_btn button {
  background-color: var(--light-blue);
  border-radius: 3px;
  border: none;
  width: 100%;
  padding: 0.5rem;
}
.confirmation_btn button:hover {
  opacity: 0.9;
}

.MuiModal-root[aria-labelledby='conf_modal'] {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confModal {
  width: 450px;
  max-height: 95%;
  background: var(--secondary-BG);
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.15);
  border-radius: 22px;
  padding: 1rem;
  margin: 1rem;
  overflow-y: auto;
}
.conf_hero_img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.conf_hero_img img {
  width: 100%;
  border-radius: 5px;
}

.confModal .conf_text {
  font-size: 16px;
  margin: 10px 0;
}

.conf_detail {
  color: #bebebe;
  font-size: 14px;
}
.conf_detail .conf_detail_header {
  font-size: 18px;
  color: var(--text-white);
  font-weight: bold;
  margin-top: 10px;
}
.conf_detail .payment_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}
.conf_detail .total_payment_row {
  color: var(--text-white);
  padding: 5px 0;
  border-top: 2px solid #60647d;
}
.confModal .editModal_close {
  float: right;
}

.confirm_modal_btn button {
  color: var(--text-white);
  background-color: var(--primary-BG);
  border: none;
  border-radius: 8px;
  font-size: 12px;
  width: 100%;
  padding: 5px;
}
.confirm_modal_btn button:hover {
  opacity: 0.9;
}

.totalPrice {
  color: var(--light-blue);
  font-weight: bold;
  font-size: 16px;
}

.paymentDetails_reviewCard {
  min-width: 400px;
  border: 1px solid;
  border-radius: 10px;
  padding: 1rem;
}
@media (max-width: 480px) {
  .paymentDetails_reviewCard {
    min-width: 300px;
  }
}
.paymentDetails_reviewCard .paymentDetails_reviewCard_Header img {
  border-radius: 10px;
  margin: 0;
  margin-bottom: 10px;
  max-width: 100%;
  max-height: 250px;
  object-fit: cover;
  width: 100%;
}
.bookingTimings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  padding: 5px 0;
  margin: 5px 0;
  gap: 2rem;
}
.bookingDiliveryDetail {
  padding: 5px 0;
  margin: 5px 0;
  border-bottom: 1px solid;
}
.bookingAirport {
  color: var(--light-blue);
  font-size: 16px;
}
.bookingPaymentDetail .conf_detail_header {
  font-size: 20px;
  font-weight: bold;
}
.bookingPaymentDetail .payment_row {
  font-size: 17px;
}
.bookingPaymentDetail .total_payment_row {
  font-size: 17px;
  background-color: #cbcbcb14;
  border-radius: 10px;
  padding: 0.5rem;
  border: none;
  font-weight: bold;
}

.paymentPage .timingsCard {
  background: transparent;
  box-shadow: none;
  padding: 0;
}

.paymentPage .timingsCard .timingsCard_submit {
  display: none;
}

.paymentDetails_wrapper .paymentDetails_card .totalPrice {
  display: none;
}

@media (max-width: 480px) {
  .paymentDetails_wrapper {
    padding: 1rem 0;
  }
  .paymentDetails_wrapper .paymentDetails_container {
    flex-direction: column-reverse;
    gap: 2rem;
  }
  .paymentDetails {
    width: 100%;
  }
  .paymentDetails_wrapper .paymentDetails .totalPrice {
    display: none;
  }

  .paymentDetails_wrapper .paymentDetails_card {
    width: 100%;
    display: block;
  }

  .paymentDetails_wrapper .paymentDetails_card .totalPrice {
    display: block;
    margin: 0.5rem 0 1rem;
  }
  .paymentDetails_wrapper .paymentDetails_card .paymentDetails_reviewCard {
    width: 100%;
  }
  .paymentDetails_wrapper .paymentDetails_card img {
    margin-top: 0;
    max-width: 100%;
    object-fit: cover;
  }

  .cardRow {
    display: block;
  }
  .cardRow_text {
    width: 100%;
  }
  .cardRow_input {
    width: 100%;
  }
  .cardRow_input input,
  .expiry .expiry_input input {
    height: 36px;
    margin-top: 10px;
  }
  .cvv_number .cvv_number_input svg {
    top: 14px;
  }
}

@media (min-width: 480px) and (max-width: 800px) {
  .cardRow_text .card_label {
    line-height: 34px;
  }
  .cardRow_text span {
    display: none;
  }
  .cardRow_input input {
    height: 34px;
  }
}

@media (max-width: 800px) {
  .paymentDetails_wrapper .paymentDetails_container {
    gap: 0.2rem;
  }
  .paymentDetails_reviewCard {
    min-width: 300px;
  }
}

/* remove Number input button */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.confirm_payment_btn button {
  background-color: #4fc8e9 !important;
  color: white;
  font-size: 16px;
  padding: 8px;
  font-weight: bold;
  margin-top: 1rem;
}

.mainLoader {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(100, 100, 100, 0.15);
  z-index: 1000;
}

.mainLoader_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.MuiButtonBase-root.MuiMenuItem-root.MuiButtonBase-root {
  display: block;
  padding: 5px;
}

.StripeElement {
  background-color: transparent;
  /* padding: 1rem 2rem 2rem; */
  /* border-radius: 10px; */
  /* padding: 0.5em 0.6em 0.5em 2.5em; */
  padding: 0.65em 0.6em;
  border: 1px solid #454d80 !important;
  font-size: inherit;
  height: 40px;
  color: #fff !important;
  border-radius: 0.2em;
}

.StripeElement-rtcar-card-num {
  padding-left: 2.75em;
}

.card-num-element {
  position: relative;
}

.card-num-element .stripe-card-svg {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 0.7em;
}
