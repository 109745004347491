.contact_us {
  display: flex;
}

.contact_us .left_layout {
  width: 60%;
}

.contact_us .right_layout {
  width: 40%;
  padding-top: 70px;
}

.contact_us .right_layout div {
  height: 60px;
  color: var(--text-grey);
  font-size: 20px;
  float: left;
  padding: 10px;
  display: block;
  width: 100%;
}

@media (max-width: 800px) {
  .contact_us {
    display: block;
    text-align: center;
  }

  .contact_us .left_layout {
    width: 100%;
  }

  .contact_us .right_layout {
    width: 100%;
    padding-top: 20px;
  }
}

.custom-toast {
  transition: all 230ms cubic-bezier(0.21, 1.02, 0.73, 1) 0s;
  animation: 0.35s cubic-bezier(0.21, 1.02, 0.73, 1) 0s 1 normal forwards
    running go3223188581;
  padding: 1rem;
  font-size: 20px;
}

.custom-toast .close {
  position: absolute;
  height: 20px;
  width: 20px;
  top: -5px;
  right: -5px;
  color: rgb(230, 82, 82);
  cursor: pointer;
}
