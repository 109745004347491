.timingsCard_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -25rem;
  position: relative;
  z-index: 100;
}

.landingTimingsCard {
  position: absolute;
  width: 100%;
  top: 33%;
  margin-top: 0;
}
.timingsCard {
  width: 77%;
  color: var(--text-white);
  background: var(--secondary-BG);
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.26);
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
}
.timingsCard .fieldsWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
}

.timingsCard .fieldsWrapper .MuiInputLabel-outlined[data-shrink='true'] {
  /* opacity: 0; */
  transform: translate(14px, -9px) scale(0);
}
.timingsCard .destination_select {
  position: relative;
}
.timingsCard .destination_select .MuiFormControl-root {
  margin: 0;
  margin-top: 10px;
}
.timingsCard .destination_select .MuiInputLabel-root {
  font-size: 12px;
  width: 140px;
  color: var(--text-white);
}
.timingsCard .destination_select .MuiInputBase-root {
  font-size: 11px;
  width: 280px;
  border: 1px solid var(--text-white);
  color: var(--text-white);
}

.timingsCard .destination_select .dropoff-airport-heading {
  margin-top: 10px;
}

.timingsCard .fieldsWrapper .deliveryCharge {
  position: absolute;
  right: 0;
  bottom: -20px;
  font-size: 12px;
  color: var(--light-blue);
}

.timingsCard .MuiMenu-paper {
  background-color: var(--secondary-BG) !important;
  color: var(--text-white) !important;
  font-size: 14px !important;
  width: 280px;
  /* left: 192px !important; */
}
.timingsCard .date_time .MuiFormControl-root {
  margin: 0;
  /* margin-top: 10px; */
}
.timingsCard .date_time .MuiInputLabel-root {
  font-size: 12px;
  width: 140px;
  color: var(--text-white);
}
.timingsCard .date_time .MuiInputBase-root {
  font-size: 11px;
  width: 140px;
  /* border: 1px solid var(--text-white); */
  color: var(--text-white);
}
.MuiMenu-paper {
  max-height: 300px !important;
}
.timings_menu_item {
  font-size: 11px !important;
  display: block!important;
  padding: 5px!important;
}

.timingsCard .destination {
  width: 280px !important;
}
.timingsCard .fieldsWrapper .date_time {
  border: 1px solid var(--text-white);
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
}

.timingsCard .time_date_picker:first-child {
  border-right: 1px solid var(--text-white);
}
.timingsCard .time_date_picker {
  width: 140px;
}
.timingsCard .MuiOutlinedInput-notchedOutline,
.timingsCard .MuiOutlinedInput-notchedOutline:hover {
  /* border: 1px solid #ffffff !important; */
  border: none !important;
  color: var(--text-white);
}
.timingsCard .time_date_picker label,
.timingsCard .time_date_picker .MuiOutlinedInput-root,
.timingsCard svg {
  color: var(--text-white);
  font-size: 12px;
}

.timingsCard_submit {
  background: var(--light-blue);
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold;
  color: var(--text-white);
  padding: 5px 2.5rem;
}
.timepicker_dropdown .ant-picker-panel-container {
  background: var(--secondary-BG);
}
.timepicker_dropdown .ant-picker-panel-container .ant-picker-panel {
  border: none;
}

.timepicker .ant-picker-input input {
  color: var(--text-white);
}
.timepicker .ant-picker-input input::placeholder {
  color: var(--text-white);
}

.timepicker .ant-picker-input:hover .ant-picker-clear {
  opacity: 0;
}
.timepicker_dropdown
  .ant-picker-panel-container
  .ant-picker-time-panel-cell-inner {
  color: var(--text-white) !important;
}
.timepicker_dropdown
  .ant-picker-panel-container
  .ant-picker-time-panel-cell-inner:hover {
  background: var(--primary-BG) !important;
}
.timepicker_dropdown
  .ant-picker-panel-container
  .ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: var(--primary-BG) !important;
}
.timepicker_dropdown
  .ant-picker-panel-container
  .ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid var(--primary-BG);
}
.timepicker_dropdown .ant-picker-footer {
  border-top: 1px solid var(--primary-BG);
  display: none;
}
.timepicker_dropdown ant-picker-footer ant-picker-ok button {
  color: var(--text-white);
  border-color: #15171f;
  background: var(--primary-BG);
  border-radius: 3px;
}

@media (max-width: 480px) {
  .timingsCard_wrapper {
    margin-top: -20rem;
    padding: 0 3%;
  }
  .timingsCard {
    width: 100%;
  }
  .timingsCard .fieldsWrapper {
    flex-direction: column;
  }
  .timingsCard_submit {
    width: 100%;
  }
}

.productDetail_timingsCard_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10rem;
  position: relative;
  z-index: 100;
  margin: 1rem 0;
}
.productDetail_timingsCard_wrapper .timingsCard {
  width: auto;
  color: var(--text-white);
  background: var(--secondary-BG);
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.26);
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 1rem 1rem;
}
.productDetail_timingsCard_wrapper .timingsCard .fieldsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.productDetail_timingsCard_wrapper
  .timingsCard
  .fieldsWrapper
  .MuiInputLabel-outlined[data-shrink='true'] {
  /* opacity: 0; */
  transform: translate(14px, -9px) scale(0);
}
.productDetail_timingsCard_wrapper .timingsCard .destination_select {
  position: relative;
  width: 100%;
}
.productDetail_timingsCard_wrapper .timingsCard .dateTimePick {
  width: 48.5%;
}
.productDetail_timingsCard_wrapper
  .timingsCard
  .destination_select
  .MuiFormControl-root {
  margin: 0;
  margin-top: 10px;
  width: 100%;
}
.productDetail_timingsCard_wrapper
  .timingsCard
  .destination_select
  .MuiInputLabel-root {
  font-size: 12px;
  width: 140px;
  color: var(--text-white);
}
.productDetail_timingsCard_wrapper
  .timingsCard
  .destination_select
  .MuiInputBase-root {
  font-size: 11px;
  width: 100%;
  /* width: 380px; */
  border: 1px solid var(--text-white);
  color: var(--text-white);
}

.productDetail_timingsCard_wrapper .timingsCard .fieldsWrapper .deliveryCharge {
  position: absolute;
  right: 0;
  bottom: -20px;
  font-size: 12px;
  color: var(--light-blue);
}

.productDetail_timingsCard_wrapper .timingsCard .MuiMenu-paper {
  background-color: var(--secondary-BG) !important;
  color: var(--text-white) !important;
  font-size: 14px !important;
  width: 280px;
  /* left: 192px !important; */
}
.productDetail_timingsCard_wrapper
  .timingsCard
  .date_time
  .MuiFormControl-root {
  margin: 0;
  /* margin-top: 10px; */
  width: 50%;
}
.productDetail_timingsCard_wrapper .timingsCard .date_time .MuiInputLabel-root {
  font-size: 12px;
  width: 140px;
  color: var(--text-white);
}
.productDetail_timingsCard_wrapper .timingsCard .date_time .MuiInputBase-root {
  font-size: 11px;
  width: 100%;
  /* width: 130px; */
  /* border: 1px solid var(--text-white); */
  color: var(--text-white);
}
.productDetail_timingsCard_wrapper .MuiMenu-paper {
  max-height: 300px !important;
}
.productDetail_timingsCard_wrapper .airport_name_menu_item {
  font-size: 11px !important;
}

.productDetail_timingsCard_wrapper .timingsCard .destination {
  width: 280px !important;
}
.productDetail_timingsCard_wrapper .timingsCard .fieldsWrapper .date_time {
  border: 1px solid var(--text-white);
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  width: 100%;
}

.productDetail_timingsCard_wrapper .timingsCard .time_date_picker:first-child {
  border-right: 1px solid var(--text-white);
}
.productDetail_timingsCard_wrapper .timingsCard .time_date_picker {
  width: 140px;
}
.productDetail_timingsCard_wrapper
  .timingsCard
  .MuiOutlinedInput-notchedOutline,
.productDetail_timingsCard_wrapper
  .timingsCard
  .MuiOutlinedInput-notchedOutline:hover {
  /* border: 1px solid #ffffff !important; */
  border: none !important;
  color: var(--text-white);
}
.productDetail_timingsCard_wrapper .timingsCard .time_date_picker label,
.productDetail_timingsCard_wrapper
  .timingsCard
  .time_date_picker
  .MuiOutlinedInput-root,
.productDetail_timingsCard_wrapper .timingsCard svg {
  color: var(--text-white);
  font-size: 12px;
}

.productDetail_timingsCard_wrapper .timingsCard_submit {
  background: var(--light-blue);
  border: none;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  color: var(--text-white);
  padding: 5px 2.5rem;
}
.productDetail_timingsCard_wrapper
  .timepicker_dropdown
  .ant-picker-panel-container {
  background: var(--secondary-BG);
}
.productDetail_timingsCard_wrapper
  .timepicker_dropdown
  .ant-picker-panel-container
  .ant-picker-panel {
  border: none;
}

.productDetail_timingsCard_wrapper .timepicker .ant-picker-input input {
  color: var(--text-white);
}
.productDetail_timingsCard_wrapper
  .timepicker
  .ant-picker-input
  input::placeholder {
  color: var(--text-white);
}

.productDetail_timingsCard_wrapper
  .timepicker
  .ant-picker-input:hover
  .ant-picker-clear {
  opacity: 0;
}
.productDetail_timingsCard_wrapper
  .timepicker_dropdown
  .ant-picker-panel-container
  .ant-picker-time-panel-cell-inner {
  color: var(--text-white) !important;
}
.productDetail_timingsCard_wrapper
  .timepicker_dropdown
  .ant-picker-panel-container
  .ant-picker-time-panel-cell-inner:hover {
  background: var(--primary-BG) !important;
}
.productDetail_timingsCard_wrapper
  .timepicker_dropdown
  .ant-picker-panel-container
  .ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: var(--primary-BG) !important;
}
.productDetail_timingsCard_wrapper
  .timepicker_dropdown
  .ant-picker-panel-container
  .ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid var(--primary-BG);
}
.productDetail_timingsCard_wrapper .timepicker_dropdown .ant-picker-footer {
  border-top: 1px solid var(--primary-BG);
  display: none;
}
.productDetail_timingsCard_wrapper
  .timepicker_dropdown
  ant-picker-footer
  ant-picker-ok
  button {
  color: var(--text-white);
  border-color: #15171f;
  background: var(--primary-BG);
  border-radius: 3px;
}

@media (max-width: 480px) {
  .productDetail_timingsCard_wrapper .timingsCard .dateTimePick {
    width: 100%;
  }
  .productDetail_timingsCard_wrapper {
    /* margin-top: -6rem; */
    padding: 0;
  }
  .productDetail_timingsCard_wrapper .timingsCard {
    width: 100%;
  }
  .productDetail_timingsCard_wrapper .timingsCard .fieldsWrapper {
    flex-direction: column;
    width: 100%;
  }
  .productDetail_timingsCard_wrapper .timingsCard_submit {
    width: 100%;
  }
}

.timelineTimingsModal {
  width: auto !important;
}
.timelineTimingsModal:focus-visible {
  outline: none;
}

.timelineTimingsCard {
  margin-top: 0 !important;
}

.timelineTimingsCard .timingsCard {
  background: transparent;
  box-shadow: none;
}
.timingsCard_wrapper .timings_error {
  padding: 3px;
  color: red;
  font-size: 15px;
  padding-top: 10px;
}
.timingsCard_wrapper .date_time .MuiOutlinedInput-input {
  text-align: center;
}