@import url('http://fonts.cdnfonts.com/css/circular-std?styles=17909,17911,17907,17905');

:root {
  overflow-x: hidden;
  /* --primary-BG: #1c2038;
  --img-wrap-bg: #1c2038b0;

  --secondary-BG: #252b48;
  --text-white: #fff;
  --text-grey: #bebebe;

  --primary-box-shadow: #14182b;
  --secondary-box-shadow: #3c4071;

  --primary-border-color: #454d80;

  --back-drop: #252b48cf;

  --light-blue: #4fc8e9; */
}

body {
  margin: 0;
  padding: 0 !important;
  font-family: 'Circular Std', sans-serif !important;
  font-weight: 450 !important;
  color: var(--text-white) !important;
  overflow-x: hidden;
  height: 100vh;
  overflow-y: auto;
}

.settings_body {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.settings_body .persoal_info {
  padding: 1rem;
  border-radius: 10px;
  background: var(--secondary-BG);
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.08);
}

.persoal_info h6 {
  color: var(--text-white);
}

.persoal_info .persoal_info_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}
.persoal_info .persoal_info_row:last-child {
  margin-top: 1rem;
}

.persoal_info .persoal_info_row input {
  background: transparent;
  border: 1px solid var(--primary-border-color);
  border-radius: 8px;
  padding: 10px 15px;
  outline: none;
  width: 100%;
  color: var(--text-white);
}

.body_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.body_row .payment_info h6,
.body_row .billing_history h6 {
  color: var(--text-white);
}
.body_row .payment_info,
.body_row .billing_history {
  color: var(--text-white);
  width: 50%;
  background: var(--secondary-BG);
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 1rem;
  height: 350px;
}

.billing_history_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.billing_history_header span svg {
  margin-right: 5px;
  width: 18px;
}

.billing_history_header span:last-child {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.billing_table table {
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0px 15px;
}
.billing_table table tbody tr td {
  color: #bebebe;
  font-size: 13px;
}

.settings_page .body_row .uploadBtn {
  width: 100%;
}
.settings_page .body_row .uploadBtn .user_upload_btn {
  background-color: var(--secondary-BG) !important;
  border: none !important;
  border-radius: 10px !important;
}
@media (max-width: 480px) {
  .persoal_info .persoal_info_row {
    flex-direction: column;
    gap: 1rem;
  }
  .body_row {
    flex-direction: column;
  }

  .body_row .payment_info,
  .body_row .billing_history {
    width: 100%;
  }
}

.page {
  display: flex;
  gap: 1rem;
  margin: 1rem;
}

.hero_content {
  padding: 1rem 0;
  margin-left: 17%;
  width: 85%;
}

.pagewrapper {
  padding: 1rem 0;
}

.booking {
  background: var(--secondary-BG);
  border-radius: 10px;
  padding: 5px 10px;
}
.booking_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.booking_header h4 {
  font-size: 16px;
  color: var(--text-white);
}
.booking_header button {
  background-color: #ff2552;
  padding: 5px 15px;
  color: var(--text-white);
  border-radius: 3px;
  border: 1px solid transparent;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
}
.booking_header button:hover {
  background-color: transparent;
  border: 1px solid #ff2552;
}
.booking .carCard_wrapper {
  border-radius: 0;
  border-bottom: 2px solid #8d94be;
  width: 100%;
}
.booking .carCard_wrapper:hover {
  box-shadow: none;
  transform: none;
}
.booking .cars_page_pagination {
  margin: 0.5rem 0;
}
.all_booking_header {
  margin: 20px 0 0;
}

.all_bookings_nav {
  border-bottom: 2px solid #8d94be;
  margin: 0 0 20px;
  display: flex;
  gap: 1rem;
}

.all_bookings_nav span {
  padding: 5px 0;
  font-size: 12px;
  cursor: pointer;
  color: #a5a5a5;
  transition: all 0.2s ease-in-out;
}

.all_bookings_nav .active {
  color: var(--text-white);
  border-bottom: 2px solid var(--light-blue);
}

@media (max-width: 480px) {
  .hero_content {
    margin-left: 0;
    width: 100%;
    padding: 0 0 1rem;
  }
}

.trip_inormation .settings_body {
  gap: 1rem;
  background: var(--secondary-BG);
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 1rem;
}

.trip_inormation .settings_body .uploadBtn {
  background-color: transparent;
  border: 1px solid var(--primary-border-color);
  border-radius: 10px;
}
