.payment_page {
  padding: 3rem 3%;
}
.payment_with_discount_code {
  margin-top: 2rem;
}

.booking_header h3 {
  color: var(--text-white);
}

.discount_input {
  position: relative;
}
.discount_input input {
  padding-right: 100px;
  color: var(--text-white);
}
.discount_input .apply_code_btn {
  color: var(--text-white);
  top: 6px;
  right: 5px;
  position: absolute;
  background: #ff2552;
  padding: 2px 10px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
}

.discount_input .apply_code_btn:hover {
  background-color: transparent;
  border-color: #ff2552;
}

@media (max-width: 480px) {
  .discount_input .apply_code_btn {
    top: 15px;
  }
}


.payment_cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  color: var(--text-white);
}

.payment_cards .payment_card {
  display: flex;
  align-items: center;
  background: var(--primary-BG);
  border-radius: 6px;
  padding: 1rem;
  gap: 1rem;
  border: none;
  height: 80px;
  color: var(--text-white);
}
.payment_cards .payment_card:focus {
  outline: 1px solid var(--light-blue);
}

.payment_cards .payment_card svg {
  width: 50px;
}
