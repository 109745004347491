.featureToast_wrapper {
  /* Increased to account for different dropoff */
  height: 180px;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}
a,
a:hover {
  color: inherit;
}
.featureToast {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.featureToast .step {
  position: relative;
  border: 1px solid var(--light-blue);
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.featureToast .step .step_text {
  position: absolute;
  top: 40px;
  left: 0;
  width: 150px;
  cursor: pointer;
}
.step_text:hover {
  text-decoration: underline;
}
.featureToast .step .step_text .heading {
  font-size: 16px;
  font-weight: bold;
}
.featureToast .step .step_text .body {
  font-size: 14px;
}
.featureToast .step svg {
  margin-top: 2px;
}

.featureToast .step_connect {
  width: 22%;
  border-top: 1px dotted;
  position: relative;
}
.featureToast .step_connect svg {
  position: absolute;
  top: -5px;
}

@media (max-width: 480px) {
  .featureToast_wrapper {
    height: auto;
  }
  .featureToast .step .step_text {
    display: none;
  }
  .featureToast .step_connect {
    width: 14%;
  }
}
@media (orientation: landscape) and (max-width: 780px) {
  .featureToast .step .step_text {
    width: 120px;
  }
}

.react-confirm-alert-overlay {
  background: rgba(37, 43, 72, 0.81) !important;
  overflow-y: scroll;
  align-items: center;
  z-index: 10000;
}

.header_rental_details:hover {
  cursor: pointer;
  text-decoration: underline;
}